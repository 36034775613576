import React, { ReactNode } from 'react';
import { Button, Grid } from '@material-ui/core';
import {
  EntityApiDefinitionCard,
  EntityConsumedApisCard,
  EntityConsumingComponentsCard,
  EntityHasApisCard,
  EntityProvidedApisCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityDependsOnComponentsCard,
  EntityDependsOnResourcesCard,
  EntityHasComponentsCard,
  EntityHasResourcesCard,
  EntityHasSubcomponentsCard,
  EntityHasSystemsCard,
  EntityLayout,
  EntityLinksCard,
  EntitySwitch,
  EntityOrphanWarning,
  EntityProcessingErrorsPanel,
  isComponentType,
  isKind,
  hasCatalogProcessingErrors,
  isOrphan,
} from '@backstage/plugin-catalog';
import {
  isGithubActionsAvailable,
  EntityGithubActionsContent,
  EntityRecentGithubActionsRunsCard,
} from '@backstage/plugin-github-actions';
import {
  EntityGithubInsightsContent,
  EntityGithubInsightsLanguagesCard,
  EntityGithubInsightsContributorsCard,
  EntityGithubInsightsReadmeCard,
  EntityGithubInsightsReleasesCard,
  isGithubInsightsAvailable,
} from '@roadiehq/backstage-plugin-github-insights';
import {
  EntityGithubPullRequestsContent,
  EntityGithubPullRequestsOverviewCard,
  isGithubPullRequestsAvailable,
} from '@roadiehq/backstage-plugin-github-pull-requests';
import {
  EntityUserProfileCard,
  EntityGroupProfileCard,
  EntityMembersListCard,
  EntityOwnershipCard,
} from '@backstage/plugin-org';
import { EntityTechdocsContent } from '@backstage/plugin-techdocs';
import { EmptyState } from '@backstage/core-components';
import {
  Direction,
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
import {
  RELATION_API_CONSUMED_BY,
  RELATION_API_PROVIDED_BY,
  RELATION_CONSUMES_API,
  RELATION_DEPENDENCY_OF,
  RELATION_DEPENDS_ON,
  RELATION_HAS_PART,
  RELATION_PART_OF,
  RELATION_PROVIDES_API,
} from '@backstage/catalog-model';

import {
  EntitySecurityInsightsContent,
  DependabotAlertsWidget,
  isSecurityInsightsAvailable,
  SecurityInsightsWidget,
  EntityGithubDependabotContent,
} from '@roadiehq/backstage-plugin-security-insights';

import {
  GithubIssuesCard,
  githubIssuesPlugin,
} from '@backstage/plugin-github-issues';

// import { EntitySentryCard } from '@backstage/plugin-sentry';
import { EntitySentryContent, isSentryAvailable } from '@backstage/plugin-sentry';
import { EntityJiraOverviewCard, isJiraAvailable } from '@roadiehq/backstage-plugin-jira';
import { TechDocsAddons } from '@backstage/plugin-techdocs-react';
import { ReportIssue } from '@backstage/plugin-techdocs-module-addons-contrib';
import { EntitySonarQubeCard, isSonarQubeAvailable } from '@backstage/plugin-sonarqube';
import { useEntity } from '@backstage/plugin-catalog-react';
import ApiPage from './apiPage.tsx';
// const { entity } = useEntity();
// const apiDefinitions = entity?.spec?.apiDefinitions;
// const hasApiDefinitions = apiDefinitions && apiDefinitions.length > 0;
// console.log(entity)
const EntityLayoutWrapper = (props: { children?: ReactNode }) => {
  
  // const [badgesDialogOpen, setBadgesDialogOpen] = useState(false);
  // const [playlistDialogOpen, setPlaylistDialogOpen] = useState(false);

  // const extraMenuItems = useMemo(() => {
  //   return [
  //     {
  //       title: 'Badges',
  //       Icon: BadgeIcon,
  //       onClick: () => setBadgesDialogOpen(true),
  //     },
  //     {
  //       title: 'Add to playlist',
  //       Icon: PlaylistAddIcon,
  //       onClick: () => setPlaylistDialogOpen(true),
  //     },
  //   ];
  // }, []);
  
  return (
    <>
      <EntityLayout
        // UNSTABLE_extraContextMenuItems={extraMenuItems}
        UNSTABLE_contextMenuOptions={{
          disableUnregister: 'visible',
        }}
      >
        {props.children}
      </EntityLayout>

      {/* <EntityBadgesDialog
        open={badgesDialogOpen}
        onClose={() => setBadgesDialogOpen(false)}
      />
      <EntityPlaylistDialog
        open={playlistDialogOpen}
        onClose={() => setPlaylistDialogOpen(false)}
      /> */}
    </>
  );
};

const techdocsContent = (
  <EntityTechdocsContent>
    <TechDocsAddons>
      <ReportIssue />
    </TechDocsAddons>
  </EntityTechdocsContent>
);

const cicdContent = (
  // This is an example of how you can implement your company's logic in entity page.
  // You can for example enforce that all components of type 'service' should use GitHubActions
  <EntitySwitch>
    <EntitySwitch.Case if={isGithubActionsAvailable}>
    <Grid item sm={12}>
        <EntityRecentGithubActionsRunsCard limit={4} variant="gridItem" />
      </Grid>
      <EntityGithubActionsContent />
    </EntitySwitch.Case>

    <EntitySwitch.Case>
      <EmptyState
        title="No CI/CD available for this entity"
        missing="info"
        description="You need to add an annotation to your component if you want to enable CI/CD for it. You can read more about annotations in Backstage by clicking the button below."
        action={
          <Button
            variant="contained"
            color="primary"
            href="https://backstage.io/docs/features/software-catalog/well-known-annotations"
          >
            Read more
          </Button>
        }
        />
        </EntitySwitch.Case>
        ;
      </EntitySwitch>
    );
    
    // const sentryCard = (
    //   <Grid container spacing={3} alignItems="stretch">
    //     <Grid item xs={12} sm={6} md={4}>
    //       <EntitySentryCard />
    //     </Grid>
    //   </Grid>
    // );

    const sentryPage = (
      // <Grid container spacing={3} alignItems="stretch">
      //    <Grid item xs={12} sm={6} md={4}>
          <EntitySentryContent />
      //    </Grid>
      //  </Grid>
    );
    const sonarqubePage = (
        <EntitySonarQubeCard variant="gridItem" />
        // <EntitySonarQubeCard />
    );
    const cicdCard = (
      <EntitySwitch>
        <EntitySwitch.Case if={isGithubActionsAvailable}>
          <Grid item sm={12}>
            <EntityRecentGithubActionsRunsCard limit={4} variant="gridItem" />
          </Grid>
        </EntitySwitch.Case>
      </EntitySwitch>
    );

    const gitcontributionCard = (
      <EntitySwitch>
        <EntitySwitch.Case>
            <EntityGithubInsightsContributorsCard/>        
        </EntitySwitch.Case>
      </EntitySwitch>
    );

    const gitLanguageCard = (
      <EntitySwitch>
        <EntitySwitch.Case>
            <EntityGithubInsightsLanguagesCard/>        
        </EntitySwitch.Case>
      </EntitySwitch>
    );

    const gitReadmeCard = (
      <EntitySwitch>
        <EntitySwitch.Case>
            <EntityGithubInsightsReadmeCard/>        
        </EntitySwitch.Case>
      </EntitySwitch>
    );
    
    const gitReleasesCard = (
      <EntitySwitch>
        <EntitySwitch.Case>
            <EntityGithubInsightsReleasesCard/>        
        </EntitySwitch.Case>
      </EntitySwitch>
    );

    const gitPRCard = (
      <EntitySwitch>
        <EntitySwitch.Case>
            <EntityGithubPullRequestsContent/>        
        </EntitySwitch.Case>
      </EntitySwitch>
    );

    const gitIssuesCard = (
      <EntitySwitch>
        <EntitySwitch.Case>
            <GithubIssuesCard/>        
        </EntitySwitch.Case>
      </EntitySwitch>
    );



    const gitPRReleaseCard = (
      <EntitySwitch>
        <EntitySwitch.Case>
            <EntityGithubPullRequestsOverviewCard/>        
        </EntitySwitch.Case>
      </EntitySwitch>
    );
    export const entityWarningContent = (
      <>
        <EntitySwitch>
          <EntitySwitch.Case if={isOrphan}>
            <Grid item xs={12}>
              <EntityOrphanWarning />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
    
        <EntitySwitch>
          <EntitySwitch.Case if={hasCatalogProcessingErrors}>
            <Grid item xs={12}>
              <EntityProcessingErrorsPanel />
            </Grid>
          </EntitySwitch.Case>
        </EntitySwitch>
      </>
    );

    const locationContent = (
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
      </Grid>
    );
    
    const overviewContent = (
      <Grid container spacing={3} alignItems="stretch">
        {entityWarningContent}
        <Grid item md={6}>
          <EntityAboutCard variant="gridItem" />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityCatalogGraphCard variant="gridItem" height={400} />
        </Grid>
        <Grid item md={6} xs={12}>
          <EntityLinksCard />
        </Grid>
        
        <EntitySwitch>
        <EntitySwitch.Case if={isSonarQubeAvailable}>
          <Grid item md={6}>
           <EntitySonarQubeCard variant="gridItem" />
          </Grid>
        </EntitySwitch.Case>
        </EntitySwitch>
        
        <Grid item md={6} xs={12}>
          {gitcontributionCard}
        </Grid>
        <Grid item md={4} xs={12}>
          {gitLanguageCard}
        </Grid>
        <Grid item md={4} xs={12}>
          {gitReleasesCard} 
        </Grid>
        <Grid item md={4} xs={12}>
          {gitPRReleaseCard} 
        </Grid>
        {/* <Grid item md={6}>
          <SecurityInsightsWidget/>
        </Grid> */}
  {/* Commenting DependaboltAlertsWidget because of error occured*/}
        <Grid item md={6}>
          <DependabotAlertsWidget />
        </Grid>
        <Grid item md={12}>
          <EntityHasSubcomponentsCard variant="gridItem" />
        </Grid>
        <Grid item md={12}>
          {gitIssuesCard}
        </Grid>
        
        <EntitySwitch>
         <EntitySwitch.Case if={isSentryAvailable}>
         <Grid item md={12}>
          {sentryPage}
         </Grid>
        </EntitySwitch.Case>
        </EntitySwitch>

        <Grid item md={12}>
          {gitPRCard}
        </Grid>

        <Grid item md={12}>
          {cicdCard}
        </Grid>

       {/* <Grid item md={12} xs={12}>
          {gitReadmeCard}
        </Grid> */}
      </Grid>
    );

    const locationEntityPage = (
      <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
          {locationContent}
        </EntityLayout.Route>
      </EntityLayout>
    )
    
    const serviceEntityPage = (
      <EntityLayout>
        <EntityLayout.Route path="/" title="Overview">
          {overviewContent}
        </EntityLayout.Route>
    
        <EntityLayout.Route path="/ci-cd" title="CI/CD">
          {cicdContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/issues" title="Issues">
          {gitIssuesCard}
        </EntityLayout.Route>

        <EntityLayout.Route if={isSentryAvailable} path="/Errors" title="Sentry Errors">
          {sentryPage}
        </EntityLayout.Route>

        <EntityLayout.Route if={isSonarQubeAvailable} path="/SonarQube" title="SonarQube">
          {sonarqubePage}
        </EntityLayout.Route>

        {/* <EntityLayout.Route path="/security-insights" title="Security Insights">
          Uncomment the line below if you'd like to only show the tab on entities with the correct annotations already set */}
          {/* if={isSecurityInsightsAvailable}
          <EntitySecurityInsightsContent />
        </EntityLayout.Route> */}

        <EntityLayout.Route path="/dependabot" title="Dependabot" >
          {/* Uncomment the line below if you'd like to only show the tab on entities with the correct annotations already set
          if={isSecurityInsightsAvailable} */}
          <EntityGithubDependabotContent />
        </EntityLayout.Route>

        <EntityLayout.Route if={isJiraAvailable} path="/Jira" title="JIRA">
          <EntityJiraOverviewCard/>
        </EntityLayout.Route>

        <EntityLayout.Route path="/api" title="API">
          <Grid container spacing={3} alignItems="stretch">
            <Grid item md={6}>
              <EntityProvidedApisCard />
            </Grid>
            <Grid item md={6}>
              <EntityConsumedApisCard />
            </Grid>
          </Grid>
        </EntityLayout.Route>
    
        <EntityLayout.Route path="/dependencies" title="Dependencies">
          <Grid container spacing={3} alignItems="stretch">
            <Grid item md={6}>
              <EntityDependsOnComponentsCard variant="gridItem" />
            </Grid>
            <Grid item md={6}>
              <EntityDependsOnResourcesCard variant="gridItem" />
            </Grid>
          </Grid>
        </EntityLayout.Route>

        <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
        </EntityLayout.Route>

        <EntityLayout.Route path="/pull-requests" title="Pull Requests">
        {gitPRCard}
        </EntityLayout.Route>
      </EntityLayout>
  );
  
  /**
   * NOTE: This page is designed to work on small screens such as mobile devices.
   * This is based on Material UI Grid. If breakpoints are used, each grid item must set the `xs` prop to a column size or to `true`,
   * since this does not default. If no breakpoints are used, the items will equitably share the available space.
   * https://material-ui.com/components/grid/#basic-grid.
   */
  
  const defaultEntityPage = (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        {overviewContent}
      </EntityLayout.Route>
  
      <EntityLayout.Route path="/docs" title="Docs">
        {techdocsContent}
      </EntityLayout.Route>

      <EntityLayout.Route if={isSentryAvailable} path="/Errors" title="Sentry Errors">
          {sentryPage}
        </EntityLayout.Route>

        <EntityLayout.Route if={isSonarQubeAvailable} path="/SonarQube" title="SonarQube">
          {sonarqubePage}
        </EntityLayout.Route>

        <EntityLayout.Route path="/Readme" title="README">
          {gitReadmeCard}
        </EntityLayout.Route>
    </EntityLayout>
  );
  
  const componentPage = (
    <EntitySwitch>
      <EntitySwitch.Case if={isComponentType('service')}>
        {serviceEntityPage}
      </EntitySwitch.Case>
  
      {/* <EntitySwitch.Case if={isComponentType('website')}>
        {websiteEntityPage}
      </EntitySwitch.Case> */}
  
      <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
    </EntitySwitch>
  );

  const locationPage = (
    <EntitySwitch>
      <EntitySwitch.Case>
        {locationEntityPage}
      </EntitySwitch.Case>  
    </EntitySwitch>
  );
  
  // const websiteEntityPage = (
  //   <EntityLayoutWrapper>
  //     <EntityLayout.Route path="/" title="Overview">
  //       {overviewContent}
  //     </EntityLayout.Route>
  
  //     <EntityLayout.Route path="/ci-cd" title="CI/CD">
  //       {cicdContent}
  //     </EntityLayout.Route>
  //  </EntityLayoutWrapper>
  // );

  const ApiPages = (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          {entityWarningContent}
          <Grid item md={6}>
            <EntityAboutCard />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid>
          <Grid item md={4} xs={12}>
            <EntityLinksCard />
          </Grid>
          <Grid container item md={12}>
            <Grid item md={6}>
              <EntityProvidingComponentsCard />
            </Grid>
            <Grid item md={6}>
              <EntityConsumingComponentsCard />
            </Grid>
          </Grid>
        </Grid>
      </EntityLayout.Route>
  
   {/*   <EntityLayout.Route path="/definition" title="Definition">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {hasApiDefinitions ? (
                <EntityApiDefinitionCard
                    entity={entity}
                />
            ) : (
                <p>No API definitions available</p>
            )}
          </Grid>
        </Grid>
      </EntityLayout.Route> */}
    </EntityLayout> 
   );
  
  const userPage = (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          {entityWarningContent}
          <Grid item xs={12} md={6}>
            <EntityUserProfileCard variant="gridItem" />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntityOwnershipCard variant="gridItem" />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
  
  const groupPage = (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
          {entityWarningContent}
          <Grid item xs={12} md={6}>
            <EntityGroupProfileCard variant="gridItem" />
          </Grid>
          <Grid item xs={12} md={6}>
            <EntityOwnershipCard variant="gridItem" />
          </Grid>
          <Grid item xs={12}>
            <EntityMembersListCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
  
  const systemPage = (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3} alignItems="stretch">
          {entityWarningContent}
          <Grid item md={6}>
            <EntityAboutCard variant="gridItem" />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid>
          <Grid item md={4} xs={12}>
            <EntityLinksCard />
          </Grid>
          <Grid item md={8}>
            <EntityHasComponentsCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
            <EntityHasApisCard variant="gridItem" />
          </Grid>
          <Grid item md={6}>
            <EntityHasResourcesCard variant="gridItem" />
            </Grid>
            </Grid>
          </EntityLayout.Route>
          <EntityLayout.Route path="/diagram" title="Diagram">
            <EntityCatalogGraphCard
              variant="gridItem"
              direction={Direction.TOP_BOTTOM}
              title="System Diagram"
              height={700}
              relations={[
                RELATION_PART_OF,
                RELATION_HAS_PART,
                RELATION_API_CONSUMED_BY,
                RELATION_API_PROVIDED_BY,
                RELATION_CONSUMES_API,
                RELATION_PROVIDES_API,
                RELATION_DEPENDENCY_OF,
                RELATION_DEPENDS_ON,
              ]}
              unidirectional={false}
            />
          </EntityLayout.Route>
        </EntityLayout>
      );
      
      // const domainPage = (
      //   <EntityLayout>
      //     <EntityLayout.Route path="/" title="Overview">
      //       <Grid container spacing={3} alignItems="stretch">
      //         {entityWarningContent}
      //         <Grid item md={6}>
      //           <EntityAboutCard variant="gridItem" />
      //         </Grid>
      //         <Grid item md={6} xs={12}>
      //           <EntityCatalogGraphCard variant="gridItem" height={400} />
      //         </Grid>
      //         <Grid item md={6}>
      //           <EntityHasSystemsCard variant="gridItem" />
      //         </Grid>
      //       </Grid>
      //     </EntityLayout.Route>
      //     <EntityLayout.Route if={isJiraAvailable} path="/Jira" title="JIRA">
      //       <EntityJiraOverviewCard/>
      //     </EntityLayout.Route>
      //   </EntityLayout>
      // );
      
      export const entityPage = (
        <EntitySwitch>
          <EntitySwitch.Case if={isKind('component')} children={componentPage} />
          <EntitySwitch.Case if={isKind('api')} children={<ApiPage />} />          
          <EntitySwitch.Case if={isKind('group')} children={groupPage} />
          <EntitySwitch.Case if={isKind('user')} children={userPage} />
          <EntitySwitch.Case if={isKind('system')} children={systemPage} />
          {/* <EntitySwitch.Case if={isKind('domain')} children={domainPage} /> */}
          <EntitySwitch.Case if={isKind('location')} children={locationPage} />
          <EntitySwitch.Case>{defaultEntityPage}</EntitySwitch.Case>
        </EntitySwitch>
      );
