import React, { useEffect } from 'react';
import { Grid } from '@material-ui/core';
import { entityWarningContent } from './EntityPage.tsx';
import { useEntity } from '@backstage/plugin-catalog-react';
import {
  EntityApiDefinitionCard,
  EntityConsumingComponentsCard,
  EntityProvidingComponentsCard,
} from '@backstage/plugin-api-docs';
import {
  EntityAboutCard,
  EntityLayout,
  EntityLinksCard,
} from '@backstage/plugin-catalog';
import {
  EntityCatalogGraphCard,
} from '@backstage/plugin-catalog-graph';
// Adjust this according to your Grid import if you're using MUI or any other library
// const { entity } = useEntity();
console.log("entity ", useEntity);
   //  const apiDefinitions = entity?.spec?.apiDefinitions; // Adjust according to your entity structure
 //   const hasApiDefinitions = apiDefinitions && apiDefinitions.length > 0;
const ApiPage = () => {
  useEffect(() => {
    console.log("ApiPage component rendered");
  }, []); // Runs once after the component mounts

  return (
    <EntityLayout>
      <EntityLayout.Route path="/" title="Overview">
        <Grid container spacing={3}>
        {entityWarningContent}          
        <Grid item md={6}>
            <EntityAboutCard />
          </Grid>
          <Grid item md={6} xs={12}>
            <EntityCatalogGraphCard variant="gridItem" height={400} />
          </Grid>
          <Grid item md={4} xs={12}>
            <EntityLinksCard />
          </Grid>
          <Grid container item md={12}>
            <Grid item md={6}>
              <EntityProvidingComponentsCard />
            </Grid>
            <Grid item md={6}>
              <EntityConsumingComponentsCard />
            </Grid>
          </Grid>
        </Grid>
      </EntityLayout.Route>

      <EntityLayout.Route path="/definition" title="Definition">
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <EntityApiDefinitionCard />
          </Grid>
        </Grid>
      </EntityLayout.Route>
    </EntityLayout>
  );
};

export default ApiPage;

